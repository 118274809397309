import React from 'react';
import aboutData from 'data/about.yml';

const About = () => {
  return (
    <section className="about style-7" data-scroll-index="2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="info">
              <div className="section-head style-4 mb-40">
                <div className="top-title mb-10">
                  <img src="/assets/img/line_l.png" alt=""/>
                  <h5> Service </h5>
                </div>
                <h2 className="">
                  Our <span> In House </span> <br/> Quality Assurance Guarantee.
                </h2>
              </div>
              <div className="text mb-30">
                We provide high quality goods which are backed by our own in-house quality
                assurance team, who will ensure you will always get reliable quality products.
              </div>
              <ul>
                {
                  aboutData.map((item, index) => (
                    <li className="d-flex mb-20" key={index}>
                      <i className="fas fa-check-circle color-blue7 me-2 mt-2"></i>
                      <div className="text"><strong className="color-000"> {item.title}: </strong> {item.content} </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="img">
              <img src="/assets/img/about/about7.jpg" alt="" className="main-img"/>
              <img src="/assets/img/about/about7_chart.png" alt="" className="img-chart slide_up_down"/>
              <span className="circle scale_up_down"></span>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/about/about_s4_wave.png" alt="" className="top-wave"/>
      <img src="/assets/img/about/about_s4_wave.png" alt="" className="bottom-wave"/>
      <img src="/assets/img/about/about7_lines.png" alt="" className="bg-lines"/>
    </section>
  )
}

export default About