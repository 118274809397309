import React from 'react';
import "react-modal-video/css/modal-video.css";
import scrollToSection from 'common/scrollToSection';

const Header = () => {
  return (
    <header className="style-2 overflow-hidden" data-scroll-index="1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="content text-center text-light text-capitalize">
              <div className="top_info">
                <h1 className="text-uppercase" style={{
                  "fontSize": "clamp(4rem, 11vh, 10rem)",
                  "letterSpacing": "7vw",
                  "paddingLeft": "7vw",
                }}>Fast</h1>
                <p className="fw-bold text-uppercase">
                  Export Import
                </p>
              </div>
              <div className="brands mt-200 mb-80">
              </div>
              <h4>
                We are primarily engaged in export and import <br/>
                of goods in small and large quantities <br/> across a variety of products.
              </h4>
              <p className="d-block mt-40">
                Fast Export Import is a merchant exporter based in  <br/>
                India, dealing with export of goods with the help of modern logistics and <br/>
                automation systems to meet the business needs.
              </p>
              <a onClick={scrollToSection} data-scroll-nav="2" className="btn sm-butn border text-white radius-9 mt-60 hover-darkBlue">
                <span>Read More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/header/header_2.png" alt="" className="head_shape2 wave"/>
    </header>
  )
}

export default Header