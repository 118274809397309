import React from 'react';
import services from 'data/services.yml';

const Services = () => {
  return (
    <section className="services style-7 pt-100" data-scroll-index="4">
      <div className="container">
        <div className="section-head text-center style-4 mb-50">
          <div className="top-title mb-10">
            <img src="/assets/img/line_l.png" alt=""/>
            <h5> Portfolio of Products </h5>
            <img src="/assets/img/line_r.png" alt=""/>
          </div>
          <h2 className="">
            Our Four <span> Pillars </span> <br/> of Success
          </h2>
        </div>
        <div className="content pb-100">
          <div className="row">
            {
              services.map((service, index) => (
                <div className="col-lg-3" key={index}>
                  <div className="service-item-style7">
                    <div className="img">
                      <img src={service.image} alt=""/>
                      <span className="icon">
                        <i className={service.icon}></i>
                      </span>
                    </div>
                    <div className="info">
                      <h6>{service.title}</h6>
                      <p className="text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <img src="/assets/img/shap_color_7.png" alt="" className="shap_color" style={{
        position: "absolute",
        left: 0,
        bottom: "-1px",
        width: "100 %",
      }}/>
    </section>
  )
}

export default Services