import React from 'react';

import MainLayout from 'layouts/Main';
import Navbar from 'components/Nav/OnePageNav'
import Header from 'components/Header'
import ChooseUS from 'components/ChooseUs'
import Services from 'components/Services'
import About from 'components/About'
import ChatBanner from 'components/ChatBanner'
import Footer from 'components/Footer'
import {graphql} from "gatsby";

const Index = () => {
  return (
    <MainLayout scrollTopText>
      <Navbar/>
      <Header/>
      <main>
        <About/>
        <ChooseUS/>
        <Services/>
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = ({data}) => {
  const {title} = data.site.siteMetadata;
  return (
    <>
      <title>{title}</title>
    </>
  )
}
export default Index;


export const query = graphql`
query MyQuery {
  site {
    siteMetadata {
      title
    }
  }
}
`;